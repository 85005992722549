<template>
  <div>
    <div class="change-context">
      <div v-if="currentLocation">
        <p class="label">Please select your location to proceed</p>
        <BaseComponent :is-loading="isLoading">
          <template v-if="!isLoading" slot="content">
            <a-select
              :default-value="currentLocation"
              class="context-select"
              placeholder="Select Location"
            >
              <a-select-opt-group>
                <span slot="label">Active Locations</span>
                <a-select-option
                  v-for="item in activeLocations"
                  :key="item.value"
                  :value="item.value"
                  @click="handleChange(item.value)"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group>
                <span slot="label">Deactivated Locations</span>
                <a-select-option
                  v-for="item in deactiveLocations"
                  :key="item.value"
                  :value="item.value"
                  @click="handleChange(item.value)"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </template>
        </BaseComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { get } from "lodash";
import BaseComponent from "@/components/shared-components/BaseComponent.vue";

export default {
  name: "LocationContextChange",
  components: {
    BaseComponent,
  },
  data() {
    return {
      isLoading: false,
      options: [],
      activeLocations: [],
      deactiveLocations: [],
      currentLocation: null,
    };
  },
  computed: {
    ...mapGetters(["getOrgId"]),
    ...mapGetters("locationsModule", ["getAllAvailableLocations", "getCurrentLocationSelected"]),
  },
  created() {
    this.isLoading = true;
    this.currentLocation = this.getCurrentLocationSelected;
    this.createLocationOptions(this);
    this.isLoading = false;
  },
  methods: {
    ...mapMutations("locationsModule", ["setSelectedCurrentLocation"]),
    ...mapActions("locationsModule", ["fetchOrgLocationDetails"]),
    ...mapActions([
      "toggleLocationContext",
      "fetchAllCardAccounts",
      "fetchAllAchAccounts",
      "commitSetRequireLocationDialog",
    ]),
    createLocationOptions(component) {
      component.getAllAvailableLocations.forEach((location) => {
        component.options.push({ value: location.id, label: location.name });
        if (location.isActive === true) {
          component.activeLocations.push({
            value: location.id,
            label: location.name,
          });
        } else {
          component.deactiveLocations.push({
            value: location.id,
            label: location.name,
          });
        }
      });
    },
    async changeLocation() {
      this.isLoading = true;

      const selectedLocation = this.getAllAvailableLocations.find((location) => {
        return location.id === this.currentLocation;
      });

      const detailsPayload = {
        orgId: this.getOrgId,
        locationId: this.currentLocation,
        name: get(selectedLocation, "name", ""),
        locationStatus: get(selectedLocation, "isActive", false) ? "active" : "deactivated",
      };

      this.setSelectedCurrentLocation(this.currentLocation);

      await this.fetchOrgLocationDetails({
        orgId: this.getOrgId,
        locationId: this.currentLocation,
      });

      await this.toggleLocationContext(detailsPayload);
      if (this.$route.name === "DosingHome") {
        location.reload();
      } else {
        await this.$router.push({
          name: "DosingHome",
          params: {
            locationId: selectedLocation.id
          }
        });
        this.$router.go(0);
      }
    },
    handleChange(value) {
      if (value !== this.currentLocation) {
        this.currentLocation = value;
        this.changeLocation();
      } else {
        this.commitSetRequireLocationDialog(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  color: $orange;
}
.change-btn {
  margin-left: 15px;
}
.separator {
  border-bottom: 2px solid #e6e6e6;
  margin: 0 20px;
  padding: 0;
}
.context-select {
  width: 100%;
}
</style>
